import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import useTranslations from "components/use-translations"

export default function Ozellikler() {
  const {
    genel: { ozellikler },
    akilliKilitOzellikler: {
      seoTitle,
      seoDescription,
      paragraf1,
      paragraf2,
      paragraf3,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/akilli-tahta-kilit/ozellikler"
        datePublished="2020-07-17T16:53:13+00:000"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/akilli-tahta-kilit",
            name: "Akıllı Tahta Kilit",
          },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.AkilliKilit}
          activeMenuItem={MenuItemType.Ozellikler}
        />
        <Row>
          <Col>
            <h1 className="mb-4">{ozellikler}</h1>

            <p>{paragraf1}</p>
            <p>{paragraf2}</p>
            <p>{paragraf3}</p>
          </Col>
          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.AkilliKilit}
              menuItems={[
                MenuItemType.Ozellikler,
                MenuItemType.EkranGoruntuleri,
              ]}
              activeMenuItem={MenuItemType.Ozellikler}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
